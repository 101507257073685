.swiper-pagination-bullet {
    background-color: red;
    opacity: 1;
}

.partner1 {
    /* position: relative;
    top: 100px; */
    margin-top: 10px;
    width: 180px
}

.partner2 {
    position: relative;
    top: 15px;
    width: 230px
}

.partner4 {
    position: relative;
    top: 5px;
    width: 230px
}

.partner3 {
    align-items: center;
    width: 200px;
    position: relative;
    top: -20px;
    z-index: 10;
}

@media only screen and (max-width: 750px) {
    .partner1 {
        width: 150px;

    }

    .partner2 {
        width: 180px;

    }

}


/*move swiper pagination on top to image */
.swiper-custom-pagination {
    text-align: center;
    transition: 300ms opacity;
    transform: translate3d(0, 0, 0);
    z-index: 10;
    top: 0 !important;
}

.swiper-pagination-bullet {
    background-color: red;
    opacity: 1;
}

.container {
    background: transparent
}

.badge {
    width: 110px;
}

@media only screen and (max-width: 950px) {
    .badge {
        width: 110px;
    }
}

@media only screen and (max-width: 450px) {
    .badge {
        width: 75px;
    }
}